/* If you need to add @import statements, do so up here */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;900&display=swap');
@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary-color {
   color: #81ecec;
  }
  .secondary-color {
   color: #a214ff;
  }
  .primary-font {
   font-family: 'Nunito', sans-serif;
  }
  .secondary-font {
   font-family: 'Nunito', sans-serif;
   font-weight: 300;
  }
  .display-font {
   font-family: 'Fredoka One', cursive;
  }
  html {
   width: 100%;
   overflow-x: hidden;
  }
  body {
   font-family: 'Nunito', sans-serif;
  }
  .logo-font {
   color: #81ecec;
   font-family: 'Nunito', sans-serif;
   text-transform: uppercase;
  }
  .red {
   color: #e74c3c;
  }
  a {
   -webkit-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
  }
  a.phone {
   transition: none;
   color: #fff;
   font-weight: bold;
  }
  .btn-purple {
   color: #fff;
   background-color: #8700e0;
   border-color: #8700e0;
   font-weight: bold;
  }
  .btn-purple:hover {
   color: #fff;
   background-color: #6800ad;
   border-color: #6800ad;
  }
  /* header {
   height: 150px;
   position: absolute;
   width: 100%;
   z-index: 1;
  }
  header .logo {
   position: absolute;
   top: 50%;
   left: 75px;
   transform: translateY(-50%);
   width: 140px;
  } */
  #intro {
   @apply h-screen;
   font-weight: 300;
   background-color: #1a292b;
   background-image: url('/images/intro-bg.jpg');
   background-position: center center;
   background-size: cover;
   overflow: hidden;
   position: relative;
  }
  #intro .row {
   position: relative;
  }
  #intro .nav-links {
   padding-top: 50px;
   text-align: center;
   width: 100%;
  }
  #intro .nav-links .first {
   border-right: 1px solid #9f9f9f;
  }
  #intro .nav-links a {
   color: #555;
   text-transform: uppercase;
   font-family: 'Nunito', sans-serif;
   padding: 1.2rem;
   font-weight: 900;
  }
  #intro .nav-links a:hover {
   color: #fff;
   background: #a214ff;
   text-decoration: none;
  }
  @media (max-width: 769px) {
   #intro {
     height: 95vh;
  }
   #intro strong {
     border-bottom: 4px solid #00cec9;
     font-size: 45px;
  }
  }
  .bottom-round {
   position: absolute;
   right: -0.75px;
   bottom: -0.75px;
   left: -0.75px;
   color: #1a292b;
  }
  .bottom-round svg {
   transform: scale(1.001);
   width: calc(100% + 1.5px);
   height: auto;
   transform-origin: bottom center;
   display: block;
   overflow: hidden;
  }
  .divider-image {
   border-top: 5px solid #333;
   border-bottom: 5px solid #333;
   background: #34495e;
  /* Old browsers */
   background: -moz-linear-gradient(top, #3d566e 0%, #34495e 100%);
  /* FF3.6-15 */
   background: -webkit-linear-gradient(top, #3d566e 0%, #34495e 100%);
  /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom, #3d566e 0%, #34495e 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='lighten(#34495e,5%)', endColorstr='#34495e',GradientType=0);
  /* IE6-9 */
   padding: 50px 0 0;
   position: relative;
   color: #fff;
  }
  .divider-image img {
   position: relative;
   z-index: 10000;
  }
  .section {
   padding: 0 0 2rem;
   background: #fff;
  }
  .parallax-section {
   background-repeat: no-repeat;
  }
  .section-heading {
   background-color: transparent;
   padding-top: 50px;
   text-align: center;
  }
  #about {
   background: #1a292b;
   color: #fff;
  }
  #about .bio h5 {
   margin-bottom: 0px;
  }
  #about .bio p {
   margin-top: 1rem;
   font-size: 18px;
  }
  #about .lead {
   padding: 2rem 0;
  }
  #about .lead a {
   color: white;
   text-decoration: none;
  }
  #about .lead a:hover {
   color: #8700e0;
  }
  #about .more-info-link {
   color: #b647ff;
   text-decoration: none;
  }
  #about .more-info-link:hover {
   color: #ca7aff;
  }
  .more-info-top {
   padding: 0.5em;
  }
  .more-info-top .nav-pills .nav-link {
   font-weight: bold;
   color: #999;
   text-transform: uppercase;
   border-bottom: 1px solid #ddd;
   margin-right: 10px;
   border-radius: 0;
  }
  .more-info-top .nav-pills .nav-link.active {
   background-color: transparent !important;
   color: #333 !important;
   border-color: #333;
  }
  .more-info-text li {
   margin: 5px 0;
  }
  #services {
   color: #333;
   background-image: url('/images/services-bg.png');
  }
  #services .service {
   margin-top: 2rem;
   margin-bottom: 2rem;
   padding: 2rem 0;
   background: rgba(238, 238, 238, 0.95);
  }
  #services .service h4 {
   margin-bottom: 1rem;
  }
  #services .service p {
   color: #777;
  }
  #services .service img {
   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  }
  @media (max-width: 769px) {
   #services .service {
     text-align: center;
  }
   #services .service img {
     margin-bottom: 1rem;
  }
  }
  #process {
   padding-bottom: 2rem;
   background: #34495e;
   color: #fff;
  }
  #process h2 {
   margin-bottom: 2px;
   font-family: 'Nunito', sans-serif;
   font-weight: 700;
  }
  #process h5 {
   font-family: 'Nunito', sans-serif;
   font-weight: 300;
  }
  #ourwork {
   border-top: 10px solid #454545;
   padding-bottom: 0;
   background: #f1f1f1;
  }
  #ourwork h2 {
   font-family: 'Nunito', sans-serif;
   font-weight: 400;
   font-size: 60px;
   text-transform: uppercase;
  }
  #ourwork h2 a {
   color: #454545;
   background: #fff;
   border-radius: 3px;
   padding: 0 10px;
  }
  #ourwork .project {
   position: relative;
   height: 100vh;
   padding: 2rem 2rem 0;
   background-size: cover !important;
   background-repeat: no-repeat !important;
   background-position: center center !important;
   overflow: hidden !important;
   color: #fff;
  }
  #ourwork .project .projectinfo {
   font-weight: 300;
  }
  #ourwork .project .projectinfo .skill {
   display: inline-block;
   margin-right: 10px;
   margin-bottom: 10px;
   padding: 3px 13px;
   border: 1px solid #fff;
   border-radius: 200px;
   font-weight: 300;
   font-size: 0.9rem;
   font-family: 'Nunito', sans-serif;
  }
  #ourwork .project .projectinfo .skill.filled {
   border-color: #8700e0;
   background: #8700e0;
  }
  #ourwork .project .projectinfo .skill.filled a {
   text-decoration: none;
   color: #fff;
  }
  #ourwork .project .projectthumb {
   padding-top: 50px;
  }
  #ourwork .project.carmelrv {
   background-image: url('/images/carmelrv-bg.jpg');
  }
  #ourwork .project.donperry {
   background: url('/images/donperry-bg.jpg');
  }
  #ourwork .project.orbit {
   background-image: url('/images/orbit-bg.jpg');
  }
  #ourwork .project.lahearing {
   background-image: url('/images/lahearing-bg.jpg');
   color: #e74c3c;
  }
  #ourwork .project.lahearing .skill {
   border-color: #e74c3c;
  }
  @media (max-width: 769px) {
   #ourwork .project {
     height: 100%;
  }
  }
  #contact {
   background: #34495e;
   background-image: url('/images/contact-bg.png');
   background-position: center center;
   background-size: cover;
   color: #fff;
   border-top: 2px solid #333;
   border-bottom: 2px solid #333;
  }
  @media (min-width: 768px) {
   #contact {
     height: 100vh;
  }
  }
  .contact-title {
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
   height: 0;
   width: 0;
   z-index: -1;
  }
  #footer {
   text-align: center;
   background: #2c3e50;
   padding: 15px 0;
   color: #fff;
  }
  #footer a {
   color: #fff;
  }
  #footer .attribution {
   font-size: 10px;
   color: #476481;
   text-decoration: none;
  }
  .thankyou {
   width: 100%;
   height: 100%;
   background: #34495e;
   background-image: url('/images/contact-bg.png');
   background-position: center center;
   background-size: cover;
  }
  .thankyou h1 {
   font-family: 'Nunito', sans-serif;
   color: #fff;
   width: 100%;
   text-align: center;
   margin: auto;
   padding-top: 25%;
   font-weight: normal;
   text-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  }

  /*Forms/Inputs*/

  .form-field-label {
    @apply block text-gray-600 text-sm mb-2;
  }
  .form-field {
    @apply bg-white block px-4 py-2 w-full shadow-sm md:text-sm border border-slate-900/5 rounded-md outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-200 ; 
  }
  .form-select {
    @apply block w-full rounded-md border border-slate-900/5 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 duration-200;
  }
  .input-toggle {
    @apply relative w-10 h-5 transition-all duration-200 ease-in-out bg-gray-400 rounded-full shadow-inner outline-none appearance-none; 
  }
  .input-toggle:before {
      content: '';
      @apply absolute w-5 h-5 rounded-full top-0 left-0 scale-100 bg-white;
      box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
      transition: .2s ease-in-out;
  }
  .input-toggle:checked {
    @apply bg-primary-500 bg-none;
  }
  .input-toggle:checked:before {
    @apply left-5;
  }
}